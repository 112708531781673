<template>
  <pageLayout title="關於我們">
    <section class="company-intro">
      <h2>公司簡介</h2>
      <p>東宸資訊工程有限公司是一家專業的資訊科技服務提供商，致力於為企業和組織提供全方位的IT解決方案。我們的使命是通過創新的技術和卓越的服務，幫助客戶實現數位轉型，提高運營效率，並在競爭激烈的市場中保持領先地位。</p>
    </section>
    <section class="our-expertise">
      <h2>專業領域</h2>
      <p>憑藉豐富的行業經驗和專業知識，我們為客戶提供以下核心服務：</p>
      <ul>
        <li><strong>安全與監控</strong></li>
        <li><strong>基礎設施建設</strong></li>
        <li><strong>IT支持與維護</strong></li>
        <li><strong>數據與分析</strong></li>
        <li><strong>軟體開發</strong></li>
        <li><strong>網頁設計</strong></li>
        <li><strong>前沿技術</strong></li>
      </ul>
    </section>
    <section class="our-approach">
      <h2>方法</h2>
      <p>在東宸資訊工程，我們秉持以下原則：</p>
      <ul>
        <li>客戶至上</li>
        <li>技術創新</li>
        <li>品質保證</li>
        <li>持續支持</li>
      </ul>
    </section>
    <section class="conclusion">
      <p>選擇東宸資訊工程，就是選擇一個可靠的IT合作夥伴。我們期待與您攜手，共同應對數位時代的挑戰，實現業務的持續增長和成功。</p>
    </section>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageAbout',
  components: {
    pageLayout
  }
}
</script>

<style scoped>
section {
  margin-bottom: 2rem;
}

h2 {
  color: #333;
  margin-bottom: 1rem;
}

p, ul {
  margin-bottom: 1rem;
  line-height: 1.6;
}

ul {
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

.conclusion {
  font-style: italic;
}
</style>