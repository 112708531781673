<template>
  <pageLayout title="聯繫我們">
    <section class="contact-info">
      <h2>公司聯繫資訊</h2>
      <p><strong>公司名稱：</strong>東宸資訊工程有限公司</p>
      <p><strong>統一編號：</strong>82996460</p>
      <p><strong>手機：</strong><a href="tel:0911187040">0911187040</a></p>
      <p><strong>電子郵件：</strong><a href="mailto:support@jmc-hitech.com">support@jmc-hitech.com</a></p>
      <p><strong>地址：</strong>台南市關廟區東勢里和平路22巷3號</p>
    </section>

    <section class="business-hours">
      <h2>營業時間</h2>
      <p>週一至週五：上午 9:00 - 下午 6:00</p>
      <p>週六、週日：休息</p>
      <p>國定假日：休息</p>
    </section>

<!--    <section class="contact-form">-->
<!--      <h2>聯絡我們</h2>-->
<!--      <p>如果您有任何問題或需求，歡迎填寫以下表單，我們將盡快與您聯繫：</p>-->
<!--      <form @submit.prevent="submitForm">-->
<!--        <div class="form-group">-->
<!--          <label for="name">姓名：</label>-->
<!--          <input type="text" id="name" v-model="form.name" required>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <label for="email">電子郵件：</label>-->
<!--          <input type="email" id="email" v-model="form.email" required>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--          <label for="message">訊息：</label>-->
<!--          <textarea id="message" v-model="form.message" required></textarea>-->
<!--        </div>-->
<!--        <button type="submit" class="submit-button">發送訊息</button>-->
<!--      </form>-->
<!--    </section>-->

    <p v-if="statusMessage" :class="{ 'success': statusMessage.includes('成功'), 'error': statusMessage.includes('失敗') }">
      {{ statusMessage }}
    </p>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageContact',
  components: {
    pageLayout
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      sending: false,
      status: null,
      statusMessage: ''
    }
  },
  methods: {
    async submitForm() {
      this.sending = true;
      this.status = null;
      this.statusMessage = '';

      const queryString = new URLSearchParams(this.form).toString();

      try {
        const response = await fetch(`/api/send-email?${queryString}`, {
          method: 'GET'
        });
        if (!response.ok) {
          throw new Error('傳送失敗');
        }
        alert('訊息已成功傳送');
        this.statusMessage = '訊息已成功傳送';
        this.form = { name: '', email: '', message: '' };
      } catch (error) {
        console.error('Error sending email:', error);
        this.status = 'error';
        this.statusMessage = '發送訊息時出現錯誤，請稍後再試或直接聯繫我們。';
      }
    }
  }
}
</script>

<style scoped>
section {
  margin-bottom: 2rem;
}

h2 {
  color: #333;
  margin-bottom: 1rem;
}

p {
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

a {
  color: #0077be;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  height: 100px;
}

.submit-button {
  background-color: #0077be;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #005fa3;
}

.success {
  color: green;
}

.error {
  color: red;
}
</style>