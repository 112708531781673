<template>
  <div class="page-container">
    <h1>{{ title }}</h1>
    <div class="content-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageLayout',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

h1 {
  color: #0077be;
  margin-bottom: 2rem;
  text-align: center;
}

.content-wrapper {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>