<template>
  <div id="app">
    <app-layout>
      <router-view></router-view>
    </app-layout>
  </div>
</template>

<script>
import appLayout from './components/appLayout.vue'

export default {
  name: 'App',
  components: {
    appLayout
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      document.title = to.meta.title || '東宸資訊工程有限公司'
      next()
    })
  }
}
</script>


<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>