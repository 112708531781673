<template>
  <pageLayout title="歡迎來到東宸資訊工程有限公司">
    <section>
      <h2>公司簡介</h2>
      <p>東宸資訊工程有限公司是您值得信賴的IT解決方案提供商。我們致力於為企業提供全方位的資訊科技服務，幫助您在數位時代保持競爭優勢。</p>
    </section>

    <section>
      <h2>我們的核心服務</h2>
      <ul>
        <li>安全監控系統</li>
        <li>網路整合規劃</li>
        <li>軟體開發</li>
        <li>數據分析</li>
        <li>IT基礎設施維護</li>
      </ul>
    </section>

    <section>
      <h2>為什麼選擇我們？</h2>
      <ul>
        <li>豐富的行業經驗</li>
        <li>專業的技術團隊</li>
        <li>客製化解決方案</li>
        <li>優質的售後服務</li>
      </ul>
    </section>

    <section>
      <h2>聯繫我們</h2>
      <p>如果您有任何疑問或需求，請隨時與我們聯繫。我們期待為您提供專業的IT解決方案。</p>
      <router-link to="/contact" class="contact-button">聯繫我們</router-link>
    </section>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageHome',
  components: {
    pageLayout
  }
}
</script>

<style scoped>
section {
  margin-bottom: 2rem;
}

h2 {
  color: #333;
  margin-bottom: 1rem;
}

p, ul {
  margin-bottom: 1rem;
  line-height: 1.6;
}

ul {
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

.contact-button {
  display: inline-block;
  background-color: #0077be;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #005fa3;
}
</style>