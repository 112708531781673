<template>
  <pageLayout title="我們的服務">
    <div v-for="service in services" :key="service.id" class="service-item">
      <h2>{{ service.title }}</h2>
      <p>{{ service.description }}</p>
    </div>
  </pageLayout>
</template>

<script>
import pageLayout from '@/components/pageLayout.vue'

export default {
  name: 'PageServices',
  components: {
    pageLayout
  },
  data() {
    return {
      services: [
        {
          id: 1,
          title: '門禁系統 | 監視器',
          description: '提供先進的門禁管控和高清監視系統，確保您的辦公環境安全無虞。我們的解決方案可以整合多種設備，實現智能化管理和即時監控。'
        },
        {
          id: 2,
          title: '弱電工程',
          description: '專業的弱電系統規劃與安裝，包括通訊、網路、安防等系統。我們的團隊擁有豐富的經驗，能夠為各種商業和住宅空間提供最適合的弱電解決方案。'
        },
        {
          id: 3,
          title: '網路整合規劃',
          description: '為您的企業提供全面的網路整合服務，從需求分析到系統實施，確保您的網路基礎設施能夠滿足當前和未來的業務需求。我們的解決方案涵蓋有線和無線網路、雲端整合等。'
        },
        {
          id: 4,
          title: '資訊硬體維護',
          description: '提供全方位的IT硬體維護服務，包括定期檢查、故障排除和系統升級。我們的技術團隊能夠快速回應並解決各種硬體問題，確保您的業務運營不受中斷。'
        },
        {
          id: 5,
          title: '數據分析',
          description: '運用先進的數據分析工具和技術，幫助您從海量數據中提取有價值的見解。我們的分析服務可以幫助您優化業務流程、預測市場趨勢、提高決策效率。'
        },
        {
          id: 6,
          title: '軟體開發',
          description: '提供客製化的軟體開發服務，從桌面應用到企業級系統，我們都能為您量身打造。我們採用敏捷開發方法，確保開發過程的靈活性和效率。'
        },
        {
          id: 7,
          title: '網頁設計',
          description: '創造美觀、響應式和用戶友好的網站設計。我們注重用戶體驗，確保您的網站在各種設備上都能完美呈現，同時優化搜索引擎表現，提高您的線上曝光度。'
        },
        {
          id: 8,
          title: '演算法開發',
          description: '專門為複雜問題開發高效的演算法解決方案。我們的團隊擅長機器學習、人工智能和大數據處理等領域的演算法設計，幫助您實現數據驅動的創新。'
        }
      ]
    }
  }
}
</script>


<style scoped>
.service-item {
  margin-bottom: 2rem;
}

h2 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

p {
  margin-bottom: 1rem;
  line-height: 1.6;
}
</style>